import React from "react";
import classNames from "classnames";

import { Intent, IntentProps } from "../../../common/props";

export type InputGroupProps = React.InputHTMLAttributes<HTMLInputElement> & IntentProps;

export const InputGroup: React.FC<InputGroupProps> = ({
  className,
  type = "text",
  intent = Intent.None,
  ...otherProps
}: InputGroupProps) => {
  return (
    <input
      {...otherProps}
      type={type}
      className={classNames(getInputClassName(intent), className)}
    />
  );
};

function getInputClassName(intent: Intent): string {
  return "rounded px-3 py-2 text-gray-1 shadow-inner border border-gray-6";
}
