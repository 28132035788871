import { useI18next } from "gatsby-plugin-react-i18next";

type URLAnalyzer = {
  path: string,
};

export function useURLAnalyzer(): URLAnalyzer {
  let { path } = useI18next();

  if (path.length > 0 && path[0] !== "/") {
    path = "/" + path;
  }

  return {
    path: path,
  };
}

// This will not work with URL and empty paths
export function joinPaths(...paths: string[]): string {
  let result = "";
  for (let i = 0; i < paths.length; i++) {
    for(const c of paths[i]) {
      if (result.length > 0 && result[result.length - 1] === "/" && c === "/") {
        continue;
      }
      result += c;
    }
    if (result.length > 0 && result[result.length - 1] !== "/" && i !== paths.length - 1) {
      result += "/";
    }
  }
  return result;
}
