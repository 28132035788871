import React from "react";
import { GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import classNames from "classnames";

const LOCATION: google.maps.LatLngLiteral = {
  lat: 20.9905459,
  lng: 105.8659034,
};

export type LocationMapProps = {
  height: number;
  containerClassName?: string;
};

export const LocationMap: React.FC<LocationMapProps> = ({
  height,
  containerClassName,
}: LocationMapProps) => {
  const { language } = useI18next();
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = React.useState(true);

  return (
    <LoadScript
      googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
      language={language}
    >
      <GoogleMap
        mapContainerClassName={classNames(containerClassName, "w-full")}
        mapContainerStyle={{ height: `${height}px` }}
        center={LOCATION}
        zoom={15}
      >
        <Marker
          position={LOCATION}
          onClick={() => setShowInfo(true)}
        >
          {showInfo &&
            <InfoWindow
              onCloseClick={() => setShowInfo(false)}
            >
              <div className="max-w-xs">
                <div className="font-bold">{t("company.name")}</div>
                <div>{t("company.address")}</div>
                <a className="text-blue-2 hover:underline" href="https://goo.gl/maps/Gm17FY3vPeFbZLL79" target="_blank" rel="noopener noreferrer">
                  Google Maps
                </a>
              </div>
            </InfoWindow>
          }
        </Marker>
      </GoogleMap>
    </LoadScript>
  );
};
