export type ClassNameProps = {
  className?: string;
};

export enum Intent {
  None,
  Primary,
  Success,
  Warning,
  Danger,
}

export type IntentProps = {
  intent?: Intent;
};
