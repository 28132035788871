import React from "react";

import { InputGroup } from "../../../../core/forms/InputGroup";
import { FormGroup } from "../../../../core/forms/FormGroup";
import { joinPaths, useURLAnalyzer } from "../../../../../utils";

export const ContactForm: React.FC = () => {
  const { path } = useURLAnalyzer();

  const successURL = joinPaths(path, "success");

  return (
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action={successURL}
    >
      {/* Prevent SPAM using honeypot */}
      <InputGroup type="hidden" name="bot-field"/>

      <FormGroup
        helperText="Tên không được để trống"
        label="Tên của bạn"
        labelFor="contact-name"
      >
        <InputGroup
          id="contact-name"
          name="contact-name"
          required={true}
          className="w-2/3"
        />
      </FormGroup>

      <FormGroup
        className="mt-2"
        helperText="Email không được để trống"
        label="Email"
        labelFor="contact-email"
      >
        <InputGroup
          id="contact-email"
          name="contact-email"
          required={true}
          className="w-2/3"
        />
      </FormGroup>
    </form>
  );
};
