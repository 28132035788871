import React from "react";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";

import { ContactForm } from "./form/ContactForm";
import { LocationMap } from "./LocationMap";

import { DefaultLayout } from "../../layouts/DefaultLayout";
import { Icon } from "../../../core/icon/Icon";
import { IconName } from "../../../core/icon/iconName";

export const ContactPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <Helmet>
        <title>{t("nav.contact")} | {t("company.name")}</title>
      </Helmet>
      <div className="bg-gray-8">
        <div className="container mx-auto py-10">
          <h1 className="text-blue-3 font-semibold font-display text-6xl text-center">Liên hệ</h1>
          <h2 className="font-medium text-center text-gray-1 text-lg">
            Hãy liên hệ với chúng tôi để được tư vấn về công trình của bạn
          </h2>
          <div className="flex mt-8">
            <div className="w-1/2">
              <ContactForm/>
            </div>
            <div className="w-1/2">
              <div className="font-display text-semibold text-2xl text-blue-3">Địa chỉ</div>
              <div className="text-gray-1 text-sm">
                <a className="hover:underline" href="https://goo.gl/maps/Gm17FY3vPeFbZLL79" target="_blank" rel="noopener noreferrer">
                  1907 Sunshine Palace,<br/>
                  Đường 13 Lĩnh Nam, Phường Mai Động, Quận Hoàng Mai, Hà Nội
                </a>
              </div>
              <div className="font-display text-semibold text-2xl text-blue-3 mt-2">Điện thoại</div>
              <div className="text-sm">
                <div className="flex">
                  <a href="tel:84-983-120-599" className="flex items-center text-gray-1 hover:underline">
                    <Icon className="h-3 w-3 mr-2 inline" icon={IconName.Phone}/>
                    <span>0983 120 599</span>
                  </a>
                </div>
                <div className="flex mt-2">
                  <a href="tel:84-24-6292-3833" className="flex items-center text-gray-1 hover:underline">
                    <Icon className="h-3 w-3 mr-2 inline" icon={IconName.Phone}/>
                    <span>024 6292 3833</span>
                  </a>
                </div>
              </div>
              <div className="font-display text-semibold text-2xl text-blue-3 mt-2">Email</div>
              <div className="text-sm flex">
                <a href="mailto:vsj.infor@gmail.com" className="flex items-center text-gray-1 hover:underline">
                  <Icon className="h-4 w-4 mr-2 inline" icon={IconName.Envelop}/>
                  <span>vsj.infor@gmail.com</span>
                </a>
              </div>
              <LocationMap
                height={500}
                containerClassName="mt-5"
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
