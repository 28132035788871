import React from "react";
import classNames from "classnames";

import { ClassNameProps, Intent, IntentProps } from "../../../common/props";

export type FormGroupProps = IntentProps & ClassNameProps & {
  // Class names which are passed to the element that contains `children`.
  contentClassName?: string;

  // Helper text which is displayed beneath `children`. Helper text color is
  // determined by the `intent`.
  helperText?: React.ReactNode;

  // Label of this form group.
  label?: React.ReactNode;

  // `id` attribute of the labelable form element that this `FormGroup` controls
  // used as `<label for>` attribute.
  labelFor?: string;
};

export const FormGroup: React.FC<FormGroupProps> = ({
  contentClassName,
  className,
  helperText,
  label,
  labelFor,
  intent = Intent.None,
  children,
}: React.PropsWithChildren<FormGroupProps>) => {
  return (
    <div className={className}>
      {label && (
        <label className="font-semibold text-gray-1" htmlFor={labelFor}>{label}</label>
      )}
      <div className={classNames(contentClassName, "mt-1")}>
        {children}
        {helperText && (
          <div className={getHelperTextClassName(intent)}>{helperText}</div>
        )}
      </div>
    </div>
  );
};

function getHelperTextClassName(intent: Intent): string {
  const classes = "text-xs mt-1";
  switch (intent) {
  case Intent.Primary:
    return classNames(classes, "text-blue-3");
  case Intent.Success:
    return classNames(classes, "text-green-3");
  case Intent.Warning:
    return classNames(classes, "text-green-3");
  case Intent.Danger:
    return classNames(classes, "text-green-3");
  default:
    return classNames(classes, "text-gray-3");
  }
}
